<template>
    <div class="all-box-state-content">
        <div class="all-box-state-titleBar">
            <el-button size="mini" type="primary" style="margin-left: 20px;" @click="refresh">刷新</el-button>
        </div>
        <div class="all-box-state-content-content">
            <el-table
                    :data="tableData"
                    height="calc(100%)"
                    v-loading="loading"
                    style="calc(100%)">
                <el-table-column
                        prop="deviceGroupName"
                        label="设备分组名称"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="deviceName"
                        label="设备名称"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="serialNumber"
                        label="设备序列号"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="boxNumber"
                        label="箱号"
                        width="auto">
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
    export default {
        name: "allBoxState",
        data() {
            return {
                tableData: [],
                loading: false
            }
        },
        mounted() {
            this.refresh()
        },
        methods: {
            refresh() {
                this.loading = true
                setTimeout(() => {
                    axios.get('/doorDevice/getAllUsefulBox')
                        .then((res) => {
                            this.loading = false
                            if (res.data.code === 1) {
                                this.tableData = res.data.data
                                return
                            }
                            this.tableData = []
                        })
                        .catch((error) => {
                            this.loading = false
                            this.tableData = []
                        })
                }, 500)
            }
        }
    }
</script>

<style class="less">

    .all-box-state-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .all-box-state-titleBar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .all-box-state-content-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        flex: 1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        margin-bottom: 10px;
    }
</style>